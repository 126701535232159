@media print {
    * {
        box-shadow: none !important;
        height: auto!important;
        min-height: 0!important;
        max-height: none!important;
    }

    #main-sidebar, #topbar, .noprint {
        display: none !important;
    }

    .report .candidacy-data {
        /*text-align: center;*/
    }

    .ui.segment {
        border: none !important;
    }


}