/* generic */

body {
    background-color: #f6f6f6;
}

.text-centered {
    text-align: center;
}

.enf {
    font-weight:bold;
    color: black;
}


body, html, #root {
    min-height: 100%;
    padding: 0;
    margin: 0;
}

#root {
    min-height: 100vh;
    flex-direction: column;
}

#root > .pushable > .pusher {
    /* IE11 scroll FIX */
    overflow: auto;
}

#logo {
    width: 80%;
    margin-top: 20px;
    margin-bottom: 20px;
}

#topbar {
    border-radius: 0;
    margin-bottom: 0;
}

#container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

#main-wrapper {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    min-height: calc(100vh - 40px);
}

#footer-wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    width: 100vw;
}

#main-content {
    flex: 1 1 auto;
}

#main-sidebar {
    flex: 0 0 250px;
    order: -1;
    min-height: calc(100vh - 40px);
}

#main-sidebar > .ui.menu {
    height: 100%;
    border-radius: 0;
}

.mauto {
    margin: auto;
}

/* menu list */

#menu-list .item {
    text-transform: uppercase;
    font-size: .8em;
    font-weight: bold;
    line-height: 1.3em;
}

/* -- */

/* icons in dimmer and sidebars */

.dimmer .ui.button.basic:hover, .sidebar .ui.button.basic:hover {
    background-color: transparent !important;
}

.dimmer .ui.button.basic:hover .icon, .sidebar .ui.button.basic:hover .icon {
    opacity: 1;
}

.ui.button.a-bit-more-right {
    margin-left: 50vw;
}

    /* -- */

/* generic classes for some space */
#main-wrapper .ui.margin-bottom {
    margin-bottom: 15px;
}

.margin-left {
    margin-left: 5px !important;
}

.padded-left {
    padding-left: 1rem;
}

.padded-bottom {
    padding-bottom: 1rem;
}

.padded-top {
    padding-top: 1rem;
}

.padded-right {
    padding-right: 1rem;
}

.padded {
    padding: 1rem;
}

button.ui.margin-top, .ui.margin-top {
    margin-top: 15px !important;
}

@media only screen and (max-width: 767px) {

}
/* -- */

/* login */
#login {
    display: flex;
    min-height: 100%;
    align-items: center;
}

#login .login-container {
    max-width: 900px;
    margin: auto;
}

@media only screen and (max-width: 767px) {
    #login {
        margin-top: 0;
    }
}

#login-logo {
    width: 100%;
}

.login-intro {
    text-align: center;
    color: #777;
    font-size: 1.1em;
}

.login-intro a {
    color: rgb(246,45,81);
}
/* -- */

/* more info on mobile */
.ui.button.more-info-btn {
    position: absolute;
    right: 0;
    bottom: 100px;
    margin-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
/* -- */

/* images as a background */

.product-preview {
    height: 50vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

/* -- */

/* footer */

footer {
    background-color: white;
    /*height: 40px;*/
    border-top: 1px solid rgba(34,36,38,.15);
    border-left: 1px solid rgba(34,36,38,.15);
    padding: 5px 5px 5px 1.5rem;
    line-height: 2rem;
    color: #aaa;
}

/* -- */

/* ratings with different colors */

[data-entity="award-category"] .blue.ui.rating .active.icon,
[data-entity="award-category"] .blue.ui.rating .selected.icon {
    color: #0d71bb !important;
    text-shadow: 0 -1px 0 #5ab9ff,-1px 0 0 #5ab9ff,0 1px 0 #5ab9ff,1px 0 0 #5ab9ff !important;
}

/* -- */

/* fake lightbox */

.fake-image-btn {
    position: relative;
    border: none;
    cursor: pointer;
    height: 200px;
    background-size: contain;
    width: 100%;
    background-repeat: no-repeat;
    background-color: transparent;
}

.fake-image-btn.free-height {
    height: auto;
    max-height: 40vh;
}

.fake-lightbox {
    height: 80vh;
    width: 100%;
    background: transparent no-repeat center;
    background-size: contain;
}

.fake-image-btn img {
    max-width: 100%;
    max-height: 200px;
}

.fake-image-btn.free-height img {
    max-height: 40vh;
}

.fake-image-btn .fake-image-cover {
    width: 100%;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
}

/* -- */

/*// Semantic UI has these classes, however they're only applicable to
// grids, containers, rows and columns.
// plus, there isn't any `mobile hidden`, `X hidden` class.
// this snippet is using the same class names and same approach
// plus a bit more but to all elements.
//
// see https://github.com/Semantic-Org/Semantic-UI/issues/1114*/

/* Mobile */
@media only screen and (max-width: 767px) {
    [class*="mobile hidden"],
    [class*="tablet only"]:not(.mobile),
    [class*="computer only"]:not(.mobile),
    [class*="large screen only"]:not(.mobile),
    [class*="widescreen only"]:not(.mobile),
    [class*="or lower hidden"] {
        display: none !important;
    }
}

/* Tablet / iPad Portrait */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    [class*="mobile only"]:not(.tablet),
    [class*="tablet hidden"],
    [class*="computer only"]:not(.tablet),
    [class*="large screen only"]:not(.tablet),
    [class*="widescreen only"]:not(.tablet),
    [class*="or lower hidden"]:not(.mobile) {
        display: none !important;
    }
}

/* Computer / Desktop / iPad Landscape */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    [class*="mobile only"]:not(.computer),
    [class*="tablet only"]:not(.computer),
    [class*="computer hidden"],
    [class*="large screen only"]:not(.computer),
    [class*="widescreen only"]:not(.computer),
    [class*="or lower hidden"]:not(.tablet):not(.mobile) {
        display: none !important;
    }
}

/* Large Monitor */
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
    [class*="mobile only"]:not([class*="large screen"]),
    [class*="tablet only"]:not([class*="large screen"]),
    [class*="computer only"]:not([class*="large screen"]),
    [class*="large screen hidden"],
    [class*="widescreen only"]:not([class*="large screen"]),
    [class*="or lower hidden"]:not(.computer):not(.tablet):not(.mobile) {
        display: none !important;
    }
}

/* Widescreen Monitor */
@media only screen and (min-width: 1920px) {
    [class*="mobile only"]:not([class*="widescreen"]),
    [class*="tablet only"]:not([class*="widescreen"]),
    [class*="computer only"]:not([class*="widescreen"]),
    [class*="large screen only"]:not([class*="widescreen"]),
    [class*="widescreen hidden"],
    [class*="widescreen or lower hidden"] {
        display: none !important;
    }
}

.ui.card .extra.content {
    color: #333;
    font-size: 1.1em;
}


img.hidden {
    visibility: hidden;
}

.ui.active.dimmer.modals.transition {
    display: flex !important;
    display: -ms-flexbox !important;
}

.ui.active.dimmer.modals.transition > .ui.basic.modal {
    margin-top: 0!important;
    position: relative;
}

.dimmer.page > .content {
    display: flex;
    min-height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.authError {
    margin-top: 10px;
    padding: 1.616rem;
}

.big-link-container > a {
    font-size: 1.5rem;
    color: rgb(246,45,81);
}

div.logo-image {
    float: right;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.logo-image > img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
}

.no-padding-bottom {
    padding-bottom: 0 !important;
}

.no-padding-top {
    padding-top: 0 !important;
}

.social-login {
    margin-bottom: 0 !important;
}

.vote-btn-container {
    text-align: center;
    margin-top: 2rem;
}

.report-ifn-link {
    /*display: inline-block;*/
}

.report-ifn-link-multi {
    display: block;
}

@media only screen and (max-width: 991px) {
    .report .candidacy-data {
        /*text-align: center;*/
    }
}

.candidacy-data p {
    margin-bottom: 0;
}


img.selectable {
    cursor: pointer;
    border: 2px solid transparent;;
}

img.selected {
    border: 2px solid #5ab9ff;
}

.thumbContainer {
    display: flex;
    flex-wrap: wrap;
}

div.thumbContainer img {
    width: 80px;
}

@media only screen and (max-width: 991px) {
    .thumbContainer {
        justify-content: center;
    }
}
